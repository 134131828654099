<template>
  <nav>
    <nut-tabbar
      ref="nutTabber"
      :tabbar-list="tabList3"
      :bottom="true"
      :replace="true"
    >
    </nut-tabbar>
  </nav>
</template>

<script>
import { eventBus } from "../main";
export default {
  name: "navs",
  components: {},
  data() {
    return {
      tabList3: [
        {
          tabTitle: this.$t("地图"),
          curr: true,
          icon: require("@/assets/icon/navs/ditu1.png"),
          activeIcon: require("@/assets/icon/navs/ditu.png"),
          href: "/#/Home",
        },
        {
          tabTitle: this.$t("非遗"),
          curr: false,
          icon: require("../assets/icon/navs/feiyi.png"),
          curr: false,
          activeIcon: require("@/assets/icon/navs/feiyi1.png"),
          href: "/#/feiyi",
        },
        {
          tabTitle: this.$t("商城"),
          curr: false,
          icon: require("@/assets/icon/navs/store.png"),
          activeIcon: require("@/assets/icon/navs/stores.png"),
          href: "/#/store",
        },

        {
          tabTitle: this.$t("我"),
          curr: false,
          icon: require("@/assets/icon/navs/wode.png"),
          activeIcon: require("@/assets/icon/navs/wode1.png"),
          href: "/#/mine",
        },
      ],
    };
  },
  created() {
    eventBus.$on("comeOn", () => {
      this.$refs.nutTabber.switchTabs(
        {
          tabTitle: this.$t("地图"),
          curr: true,
          icon: require("@/assets/icon/navs/ditu1.png"),
          activeIcon: require("@/assets/icon/navs/ditu.png"),
          href: "/#/Home",
        },
        0
      );
    });
  },
  mounted() {
    if (window.location.href.indexOf("mine") !== -1) {
      this.$refs.nutTabber.switchTabs(
        {
          tabTitle: this.$t("Me"),
          curr: false,
          icon: require("@/assets/icon/navs/wode.png"),
          activeIcon: require("@/assets/icon/navs/wode1.png"),
          href: "/#/mine",
        },
        3
      );
    }
  },
  methods: {
    // tabSwitch3(value, index) {},
  },
};
</script>

<style lang="less" scoped>
nav {
  padding: 1vh 0;
  // display: flex;
  justify-content: space-around;
  position: fixed;
  bottom: 0;
  overflow: hidden;
  width: 100%;
  height: 8vh;
  text-align: center;
  font-size: 0.18rem;
  /deep/ .nut-tabbar {
    margin-top: 0;
    height: 8vh;
    border-bottom: none;
    z-index: 1000;
  }
  /deep/ .curr {
    color: #add;
  }
  /deep/ .icon-box .icon {
    margin-bottom: -10%;
    width: 0.6rem;
    height: 0.6rem;
    background: no-repeat;
    background-position: center center;
    background-size: 100% 100%;
  }
}
</style>
