<template>
  <div>
    <router-view />
    <!-- 底部导航栏 -->
    <Navs></Navs>
  </div>
</template>

<script>
import Navs from "./navs.vue";
export default {
  components: {
    Navs,
  },
  data() {
    return {
      tabList3: [
        {
          tabTitle: "主页",
          curr: true,
          icon: require("@/assets/icon/navs/home.png"),
          activeIcon: require("@/assets/icon/navs/homes.png"),
          href: "/#/Home",
        },
        {
          tabTitle: "非遗",
          icon: require("../assets/icon/navs/feiyi.png"),
          curr: false,
          activeIcon: require("@/assets/icon/navs/feiyis.png"),
          href: "/#/feiyi",
        },
        {
          tabTitle: "商城",
          curr: false,
          icon: require("@/assets/icon/navs/store.png"),
          activeIcon: require("@/assets/icon/navs/stores.png"),
          href: "/#/store",
        },
        {
          tabTitle: "我的",
          curr: false,
          icon: require("@/assets/icon/navs/mine.png"),
          activeIcon: require("@/assets/icon/navs/mines.png"),
          href: "/#/mine",
        },
      ],
    };
  },
};
</script>

<style lang="less" scoped>
/deep/ .nut-tabbar {
  display: flex;
  justify-content: space-around;
  // bottom: 0;
  overflow: hidden;
  width: 100%;
  height: 10vh;
  text-align: center;
  font-size: 0.18rem;
}
div /deep/ .curr {
  color: #1296db;
}
</style>